const COINGECKO_ASSETS = {
    'ATH': 'aethir'  // mapping of your symbol to gecko ID
};
const PRICE_CACHE = new Map();
const CACHE_DURATION = 19000; // 9.9s cache


// In assets.js, modify your LOGO_CACHE initialization:
const savedLogos = localStorage.getItem('logoCache');
const LOGO_CACHE = new Map(savedLogos ? JSON.parse(savedLogos) : []);

// Save to localStorage whenever we update the cache
const updateLogoCache = (symbol, url) => {
    LOGO_CACHE.set(symbol, url);
    localStorage.setItem('logoCache', JSON.stringify([...LOGO_CACHE]));
};

const assets = [
    {
        symbol: "FET",
        name: "fetch.ai",
        geckoId: "fetch-ai",
        terms: [
            {
                expiryDate: "2024-11-22T08:00:00Z",
                volatility: 0.62,
                strikePercentages: [5, 10, 15]
            },
            {
                expiryDate: "2024-11-29T08:00:00Z", // Example dates - adjust as needed
                volatility: 0.655,
                strikePercentages: [5, 10, 15]
            },
            {
                expiryDate: "2024-12-06T08:00:00Z", // Example dates - adjust as needed
                volatility: 0.73,
                strikePercentages: [5, 10, 15]
            },
        ]
    },
    {
        symbol: "IO",
        name: "io.net",
        geckoId: "io",
        terms: [
            {
                expiryDate: "2024-11-22T08:00:00Z",
                volatility: 0.65,
                strikePercentages: [5, 10, 15]
            },
            {
                expiryDate: "2024-11-29T08:00:00Z", // Example dates - adjust as needed
                volatility: 0.7,
                strikePercentages: [5, 10, 15]
            },
            {
                expiryDate: "2024-12-06T08:00:00Z", // Example dates - adjust as needed
                volatility: 0.75,
                strikePercentages: [5, 10, 15]
            },
        ]
    },
    {
        symbol: "ATH",
        name: "Aethir",
        geckoId: "aethir",
        terms: [
            {
                expiryDate: "2024-11-22T08:00:00Z",
                volatility: 0.55,
                strikePercentages: [5, 10, 15]
            },
            {
                expiryDate: "2024-11-29T08:00:00Z", // Example dates - adjust as needed
                volatility: 0.6,
                strikePercentages: [5, 10, 15]
            },
            {
                expiryDate: "2024-12-06T08:00:00Z", // Example dates - adjust as needed
                volatility: 0.65,
                strikePercentages: [5, 10, 15]
            },
        ]
    },
    {
        symbol: "APT",
        name: "Aptos",
        geckoId: "aptos",
        terms: [
            {
                expiryDate: "2024-11-22T08:00:00Z",
                volatility: 0.6,
                strikePercentages: [5, 10, 15]
            },
            {
                expiryDate: "2024-11-29T08:00:00Z", // Example dates - adjust as needed
                volatility: 0.65,
                strikePercentages: [5, 10, 15]
            },
            {
                expiryDate: "2024-12-06T08:00:00Z", // Example dates - adjust as needed
                volatility: 0.7,
                strikePercentages: [5, 10, 15]
            },
        ]
    },
];

export { assets, COINGECKO_ASSETS, PRICE_CACHE, CACHE_DURATION, LOGO_CACHE, updateLogoCache};