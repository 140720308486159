import { norm_cdf, norm_pdf, positive_sqrt } from './utils.js';

// eslint-disable-next-line no-unused-vars
function d1_fun(x, k, vol, _r, _q, _t) {
    if (vol > 0) {
        return Math.log(x / k) / vol + vol * 0.5;
    } else if (x >= k) {
        return Number.MAX_VALUE;
    } else {
        return Number.MIN_VALUE;
    }
}

function call_expected_value(x, k, r, q, t, nd1, nd2) {
    if (k <= 0) {
        return Math.exp(-r * t) * x;
    } else {
        return Math.exp(-r * t) * (x * nd1 - k * nd2);
    }
}

function put_expected_value(x, k, r, q, t, nd1, nd2) {
    if (k <= 0) {
        return 0;
    } else {
        return Math.exp(-r * t) * (k * nd2 - x * nd1);
    }
}

function call(f, k, vol, r, q, t) {
    if (t <= 0) {
        return 0;
    }
    const vol_f = vol * positive_sqrt(t);
    const d1 = d1_fun(f, k, vol_f, r, q, t);
    const d2 = d1 - vol_f;
    const nd1 = norm_cdf(d1);
    const nd2 = norm_cdf(d2);

    return call_expected_value(f, k, r, q, t, nd1, nd2);
}

function call_on_future(f, k, vol, r, t) {
    return call(f, k, vol, r, 0, t);
}

function put(f, k, vol, r, q, t) {
    if (t <= 0) {
        return 0;
    }
    const vol_f = vol * positive_sqrt(t);
    const d1 = d1_fun(f, k, vol_f, r, q, t);
    const d2 = d1 - vol_f;
    const nd1 = norm_cdf(-d1);
    const nd2 = norm_cdf(-d2);

    return put_expected_value(f, k, r, q, t, nd1, nd2);
}

function put_on_future(f, k, vol, r, t) {
    return put(f, k, vol, r, 0, t);
}

function vega(f, k, vol, r, q, t) {
    const vol_f = vol * positive_sqrt(t);
    const d1 = d1_fun(f, k, vol_f, r, q, t);
    const nd1 = norm_pdf(d1);
    return Math.exp(-r * t) * f * nd1 * positive_sqrt(t);
}

function vega_future(f, k, vol, r, t) {
    return vega(f, k, vol, r, 0, t);
}

export default { call, call_on_future, put, put_on_future, vega, vega_future };