import React, { useState, useEffect } from 'react';
import blackScholes from './black_scholes.js';
import { assets, COINGECKO_ASSETS, PRICE_CACHE, CACHE_DURATION, LOGO_CACHE, updateLogoCache } from './assets.js';
import { Mail } from 'lucide-react'; // Using lucide-react for the mail icon
import ContactButton from './ContactButton';



const getDecimalPlaces = (price) => {
    if (price >= 1000) return 2;
    if (price >= 100) return 2;
    if (price >= 10) return 3;
    if (price >= 1) return 3;
    if (price >= 0.5) return 4;
    return 5;
};

const formatPrice = (price, assetSymbol) => {
    const asset = assets.find(a => a.symbol === assetSymbol);
    const decimals = asset?.decimals || getDecimalPlaces(price);
    return price.toLocaleString(undefined, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
    });
};


const generateProductData2 = (basePrice, assetSymbol) => {
    const riskFreeRate = 0.00; // 5% annual risk-free rate
    const volatility = 0.5; // 50% annual volatility (adjust as needed for each asset)

    return [
        { percentChange: 1, days: 30 },
        // { percentChange: 2, days: 30 },
        { percentChange: 3, days: 30 },
        // { percentChange: 4, days: 3 },
        { percentChange: 5, days: 30 },
        // { percentChange: 2, days: 7 },
        // { percentChange: 4, days: 7 },
        { percentChange: 10, days: 30 },
    ].map(product => {
        const targetPrice = basePrice * (1 + product.percentChange / 100);
        const timeToExpiry = product.days / 365; // Convert days to years
        const optionPrice = blackScholes.call_on_future(basePrice, targetPrice, volatility, riskFreeRate, timeToExpiry);
        const apr = (optionPrice / basePrice) * (365 / product.days) * 100; // Annualized return

        return {
            ...product,
            targetPrice,
            optionPrice,
            apr,
            settlementDate: new Date(Date.now() + product.days * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
        };
    });
};

const calculateDaysToExpiry = (expiryDateString) => {
    const now = new Date();
    const expiry = new Date(expiryDateString);
    const diffTime = Math.abs(expiry - now);
    const diffDays = diffTime / (1000 * 60 * 60 * 24);
    return diffDays;
};

const generateProductData = (basePrice, assetSymbol) => {
    const asset = assets.find(a => a.symbol === assetSymbol);
    const riskFreeRate = 0.00;

    // Flatten all terms and their strike percentages into products
    return asset.terms.flatMap(term => {
        const daysToExpiry = calculateDaysToExpiry(term.expiryDate);

        return term.strikePercentages.map(percentChange => {
            const targetPrice = basePrice * (1 + percentChange / 100);
            const timeToExpiry = daysToExpiry / 365; // Convert days to years
            const optionPrice = blackScholes.call_on_future(
                basePrice,
                targetPrice,
                term.volatility,
                riskFreeRate,
                timeToExpiry
            );

            const apr = (optionPrice / basePrice) * (365 / daysToExpiry) * 100;

            return {
                percentChange,
                days: daysToExpiry,
                expiryDate: term.expiryDate,
                targetPrice,
                optionPrice,
                apr,
                settlementDate: new Date(term.expiryDate).toISOString().split('T')[0],
                volatility: term.volatility
            };

            return {
                percentChange,
                days: term.days,
                targetPrice,
                optionPrice,
                apr,
                settlementDate: new Date(Date.now() + term.days * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
                volatility: term.volatility
            };
        });
    }).sort((a, b) => {
        // Sort first by days, then by percentage change
        if (a.days !== b.days) {
            return a.days - b.days;
        }
        return a.percentChange - b.percentChange;
    });
};

const CryptoDashboard = () => {
    const [selectedAsset, setSelectedAsset] = useState("FET");
    const [direction, setDirection] = useState("Sell High");
    const [currentPrice, setCurrentPrice] = useState(0);
    const [products, setProducts] = useState([]);
    const [cryptoLogos, setCryptoLogos] = useState({});
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // useEffect(() => {
    //     const fetchLogos = async () => {
    //         try {
    //             const geckoIds = assets.map(asset => asset.geckoId).join(',');
    //             const response = await fetch(
    //                 `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${geckoIds}&order=market_cap_desc&per_page=100&page=1&sparkline=false`
    //             );
    //             const data = await response.json();

    //             const logos = {};
    //             data.forEach(coin => {
    //                 const asset = assets.find(a => a.geckoId === coin.id);
    //                 if (asset) {
    //                     logos[asset.symbol] = coin.image;
    //                 }
    //             });

    //             setCryptoLogos(logos);
    //         } catch (error) {
    //             console.error("Failed to fetch crypto logos:", error);
    //         }
    //     };

    //     fetchLogos();
    // }, []);

    useEffect(() => {
        const fetchLogos = async () => {
            // First check if we have all logos in cache
            const allLogosCached = assets.every(asset => LOGO_CACHE.has(asset.symbol));
            if (allLogosCached) {
                console.log('Using cached logos from localStorage');
                const cachedLogos = {};
                assets.forEach(asset => {
                    cachedLogos[asset.symbol] = LOGO_CACHE.get(asset.symbol);
                });
                setCryptoLogos(cachedLogos);
                return;
            }

            // If not all logos are cached, fetch them
            try {
                console.log('Fetching LOGOS from CoinGecko');
                const geckoIds = assets.map(asset => asset.geckoId).join(',');
                const response = await fetch(
                    `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${geckoIds}&order=market_cap_desc&per_page=100&page=1&sparkline=false`
                );
                const data = await response.json();

                const logos = {};
                data.forEach(coin => {
                    const asset = assets.find(a => a.geckoId === coin.id);
                    if (asset) {
                        logos[asset.symbol] = coin.image;
                        // Use the new updateLogoCache function
                        updateLogoCache(asset.symbol, coin.image);
                    }
                });

                setCryptoLogos(logos);
            } catch (error) {
                console.error("Failed to fetch crypto logos:", error);
                // If fetch fails, try to use any cached logos we have
                const cachedLogos = {};
                assets.forEach(asset => {
                    if (LOGO_CACHE.has(asset.symbol)) {
                        cachedLogos[asset.symbol] = LOGO_CACHE.get(asset.symbol);
                    }
                });
                if (Object.keys(cachedLogos).length > 0) {
                    setCryptoLogos(cachedLogos);
                }
            }
        };

        fetchLogos();
    }, []);


    // const fetchPrice = async (asset) => {
    //     try {
    //         const response = await fetch(`https://api.binance.com/api/v3/ticker/price?symbol=${asset}USDT`);
    //         const data = await response.json();
    //         setCurrentPrice(parseFloat(data.price));
    //     } catch (error) {
    //         console.error(`Failed to fetch ${asset} price:`, error);
    //     }
    // };

    const fetchPrice = async (asset) => {
        const cached = PRICE_CACHE.get(asset);
        if (cached) {  // Only check timestamp if we have a cached value
            let time_diff = Date.now() - cached.timestamp;
            if (time_diff < CACHE_DURATION) {
                let time_diff_seconds = time_diff / 1000;
                console.log('Fetching from cache -  dt:', time_diff_seconds + 's' + ' < ' + CACHE_DURATION / 1000 + 's');
                setCurrentPrice(cached.price);
                return;
            }
        }

        // If it's a CoinGecko asset, go directly there
        if (COINGECKO_ASSETS[asset]) {
            console.log('Fetching from CoinGecko: ', asset);
            try {
                const geckoId = COINGECKO_ASSETS[asset];
                const response = await fetch(
                    `https://api.coingecko.com/api/v3/simple/price?ids=${geckoId}&vs_currencies=usd`
                );
                if (!response.ok) throw new Error('CoinGecko API request failed');
                const data = await response.json();
                const price = parseFloat(data[geckoId].usd);
                setCurrentPrice(price);
                // Cache the result
                PRICE_CACHE.set(asset, {
                    price,
                    timestamp: Date.now()
                });
            } catch (error) {
                console.error(`Failed to fetch ${asset} price from CoinGecko:`, error);
            }
            return;
        }

        // Otherwise try Binance
        try {
            // console log fetching from binance and the asset 
            console.log('Fetching from Binance: ', asset);
            const response = await fetch(`https://api.binance.com/api/v3/ticker/price?symbol=${asset}USDT`);
            if (!response.ok) throw new Error('Binance API request failed');
            const data = await response.json();
            const price = parseFloat(data.price);
            setCurrentPrice(price);
            // Cache the result
            PRICE_CACHE.set(asset, {
                price,
                timestamp: Date.now()
            });
        } catch (error) {
            console.error(`Failed to fetch ${asset} price from Binance:`, error);
        }
    };

    useEffect(() => {
        fetchPrice(selectedAsset);
        const interval = setInterval(() => fetchPrice(selectedAsset), 10000);

        return () => clearInterval(interval);
    }, [selectedAsset]);

    useEffect(() => {
        if (currentPrice > 0) {
            setProducts(generateProductData(currentPrice, selectedAsset));
        }
    }, [currentPrice, selectedAsset]);

    const getSelectedAssetName = () => {
        const asset = assets.find(a => a.symbol === selectedAsset);
        return asset ? asset.name : selectedAsset;
    };

    const handleAssetChange = (asset) => {
        // Only reset and change if it's a different asset
        if (asset !== selectedAsset) {
            setSelectedAsset(asset);
            setCurrentPrice(0); // Reset price when changing asset
            setProducts([]); // Clear products when changing asset
        }
    };

    return (
        <div style={{
            padding: '1rem',
            backgroundColor: '#151718',
            color: 'white',
            fontFamily: 'Inter, system-ui, sans-serif',
            minHeight: '100vh'
        }}>
            <ContactButton />
            {/* Header */}
            <div style={{
                borderBottom: '1px solid #2d2d2d',
                marginBottom: '1.5rem',
                paddingBottom: '1.5rem',
                textAlign: 'center'
            }}>
                <h1 style={{
                    fontSize: '2rem', // Bigger than before
                    color: '#FFFFFF',
                    marginBottom: '0.5rem'
                }}>
                    <span style={{ fontWeight: '800' }}>Radon</span>
                    <span style={{ fontWeight: '400' }}>Trading</span>
                </h1>
                <h2 style={{  // Changed from h3 to h2 for semantic HTML
                    fontSize: '1.5rem',  // Smaller than the main title
                    fontWeight: '600',   // Less bold than Radon
                    color: '#FFFFFF',
                    marginBottom: '0.5rem'
                }}>
                    Treasury and Risk Management Solutions
                </h2>
                <p style={{
                    fontSize: '1.1rem',
                    color: '#8F8F8F',
                    margin: 0
                }}>
                    for the Crypto Market
                </p>
            </div>

            {/* Asset Selection - with horizontal scroll for mobile */}
            <div style={{
                marginBottom: '1rem',
                overflowX: 'auto',
                WebkitOverflowScrolling: 'touch',
                paddingBottom: '0.5rem' // Space for potential scrollbar
            }}>
                <div style={{
                    display: 'flex',
                    gap: '1rem',
                    minWidth: 'min-content' // Ensures buttons don't shrink
                }}>
                    {assets.map((asset) => (
                        <button
                            key={asset.symbol}
                            onClick={() => handleAssetChange(asset.symbol)}
                            style={{
                                padding: '0.5rem 1rem',
                                backgroundColor: selectedAsset === asset.symbol ? '#2d2d2d' : '#1f1f1f',
                                border: 'none',
                                borderRadius: '0.25rem',
                                color: selectedAsset === asset.symbol ? '#FFFFFF' : '#8F8F8F',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.5rem',
                                whiteSpace: 'nowrap'
                            }}
                        >
                            {cryptoLogos[asset.symbol] && (
                                <img
                                    src={cryptoLogos[asset.symbol]}
                                    alt={`${asset.name} logo`}
                                    style={{
                                        width: '24px',
                                        height: '24px',
                                        borderRadius: '50%'
                                    }}
                                />
                            )}
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span style={{ fontWeight: 'bold' }}>{asset.symbol}</span>
                                <span style={{ fontSize: '0.8rem', opacity: '0.8' }}>{asset.name}</span>
                            </div>
                        </button>
                    ))}
                </div>
            </div>

            {/* Current Price Display */}
            <div style={{ marginBottom: '1rem', color: '#FFFFFF' }}>
                <div style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                    {getSelectedAssetName()} ({selectedAsset})
                </div>
                <div>
                    Current {selectedAsset} Price: ${formatPrice(currentPrice, selectedAsset)}
                </div>
            </div>

            {/* Table with horizontal scroll for mobile */}
            <div style={{ width: '100%', maxWidth: '100%' }}>
                {!isMobile ? (
                    // Desktop table view
                    <table style={{
                        width: '100%',
                        borderCollapse: 'collapse'
                    }}>
                        <thead>
                            <tr style={{ textAlign: 'left' }}>
                                <th style={{
                                    color: '#e5e7eb',
                                    fontWeight: '600',
                                    fontSize: '1.1rem',
                                    padding: '0.75rem 0.5rem',
                                }}>Term</th>
                                <th style={{
                                    color: '#e5e7eb',
                                    fontWeight: '600',
                                    fontSize: '1.1rem',
                                    padding: '0.75rem 0.5rem',
                                }}>Target Price</th>
                                <th style={{
                                    color: '#e5e7eb',
                                    fontWeight: '600',
                                    fontSize: '1.1rem',
                                    padding: '0.75rem 0.5rem',
                                }}>APR</th>
                                <th style={{
                                    color: '#e5e7eb',
                                    fontWeight: '600',
                                    fontSize: '1.1rem',
                                    padding: '0.75rem 0.5rem',
                                }}>Settlement</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products.map((product, index) => (
                                <React.Fragment key={`${product.days}-${product.percentChange}`}>
                                    <tr>
                                        <td colSpan="4" style={{ padding: 0 }}>
                                            <div style={{
                                                borderBottom: '1px solid #27272a',
                                                width: '100%',
                                                margin: index > 0 && products[index - 1].days !== product.days ? '1rem 0' : '0'
                                            }} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: '0.75rem 0.5rem', color: '#FFFFFF' }}>
                                            {Number(product.days).toFixed(0)} Days
                                        </td>
                                        <td style={{ padding: '0.75rem 0.5rem', color: '#FFFFFF' }}>
                                            {formatPrice(product.targetPrice, selectedAsset)}
                                            <span style={{ color: '#6B7280', marginLeft: '0.5rem' }}>
                                                {product.percentChange.toFixed(2)}%
                                            </span>
                                        </td>
                                        <td style={{ padding: '0.75rem 0.5rem', color: '#22C55E' }}>
                                            {Number(product.apr).toFixed(2)}%
                                        </td>
                                        <td style={{ padding: '0.75rem 0.5rem', color: '#FFFFFF' }}>
                                            {product.settlementDate}
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    // Mobile card view
                    <div>
                        {products.map((product, index) => (
                            <div key={index} style={{
                                backgroundColor: '#1f1f1f',
                                borderRadius: '0.5rem',
                                padding: '1rem',
                                marginBottom: index > 0 && products[index - 1].days !== product.days ? '2rem' : '1rem'
                            }}>
                                <div style={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 1fr',
                                    gap: '0.5rem',
                                    fontSize: '0.9rem'
                                }}>
                                    <div>
                                        <div style={{ color: '#8F8F8F', marginBottom: '0.25rem' }}>Term</div>
                                        <div style={{ color: '#FFFFFF', fontWeight: '500' }}>
                                            {Number(product.days).toFixed(0)} Days
                                        </div>
                                    </div>

                                    <div>
                                        <div style={{ color: '#8F8F8F', marginBottom: '0.25rem' }}>APR</div>
                                        <div style={{ color: '#22C55E', fontWeight: '500' }}>{product.apr.toFixed(2)}%</div>
                                    </div>

                                    <div>
                                        <div style={{ color: '#8F8F8F', marginBottom: '0.25rem' }}>Target Price</div>
                                        <div style={{ color: '#FFFFFF', fontWeight: '500' }}>
                                            {formatPrice(product.targetPrice, selectedAsset)}
                                            <span style={{ color: '#6B7280', marginLeft: '0.5rem', fontSize: '0.8rem' }}>
                                                {product.percentChange.toFixed(2)}%
                                            </span>
                                        </div>
                                    </div>

                                    <div>
                                        <div style={{ color: '#8F8F8F', marginBottom: '0.25rem' }}>Settlement</div>
                                        <div style={{ color: '#FFFFFF', fontWeight: '500' }}>{product.settlementDate}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};


export default CryptoDashboard;



// <table style={{ width: '100%', borderCollapse: 'separate', borderSpacing: '0 0.5rem' }}>
// <thead>
//     <tr style={{ textAlign: 'left' }}>
//         <th>Target Price ({selectedAsset}/USDT)</th>
//         {/* <th>Option Price</th> */}
//         <th>APR</th>
//         <th>Settlement Date</th>
//         {/* <th>Action</th> */}
//     </tr>
// </thead>
// <tbody>
//     {products.map((product, index) => (
//         <tr key={index} style={{ backgroundColor: '#2d3748' }}>
//             <td style={{ padding: '0.75rem' }}>
//                 {formatPrice(product.targetPrice, selectedAsset).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
//                 <span style={{ color: '#a0aec0', marginLeft: '0.5rem' }}>
//                     {product.percentChange.toFixed(2)}%
//                 </span>
//             </td>
//             {/* <td style={{ padding: '0.75rem' }}>
//                 ${formatPrice(product.optionPrice, selectedAsset).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
//             </td> */}
//             <td style={{ padding: '0.75rem', color: '#48bb78' }}>{product.apr.toFixed(2)}%</td>
//             <td style={{ padding: '0.75rem' }}>
//                 {product.settlementDate}
//                 <span style={{ color: '#a0aec0', marginLeft: '0.5rem' }}>
//                     {product.days} Days
//                 </span>
//             </td>
//             {/* <td style={{ padding: '0.75rem' }}>
//                 <button
//                     style={{
//                         padding: '0.25rem 0.5rem',
//                         backgroundColor: 'transparent',
//                         border: '1px solid #3182ce',
//                         borderRadius: '0.25rem',
//                         color: 'white',
//                         cursor: 'pointer'
//                     }}
//                 >
//                     Subscribe
//                 </button>
//             </td> */}
//         </tr>
//     ))}
// </tbody>
// </table>