import React from 'react';
import CryptoDashboard from './dash.js';
import { ProtectedRoute } from './auth-components'; // Add this import

function App() {
  return (
    <div className="App">
      <ProtectedRoute>
        <CryptoDashboard />
      </ProtectedRoute>

    </div>
  );
}

export default App;