// ContactButton.js
import React from 'react';
import { Mail } from 'lucide-react';

const ContactButton = () => {
    const handleContactClick = () => {
        window.location.href = 'mailto:degenscap@gmail.com?subject=Inquiry about Treasury Solutions';
    };

    return (
        <button
            onClick={handleContactClick}
            style={{
                position: 'fixed',
                bottom: '2rem',
                right: '2rem',
                backgroundColor: '#2563eb',
                color: 'white',
                border: 'none',
                borderRadius: '0.5rem',
                padding: '0.75rem 1rem',
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem',
                cursor: 'pointer',
                boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
                transition: 'transform 0.1s ease-in-out',
                zIndex: 1000,
                ':hover': {
                    transform: 'scale(1.05)'
                }
            }}
        >
            <Mail size={20} />
            <span style={{ fontWeight: '500' }}>Contact Us</span>
        </button>
    );
};

export default ContactButton;