const EPSILON_SQRT = 1e-5;
const DBL_EPSILON = 2.2204460492503131e-16;
const EPS = 0.0001220703125;
const SQRT_OF_2_PI = 2.5066282746;

// norm_cdf constants
const A1 = 0.254829592;
const A2 = -0.284496736;
const A3 = 1.421413741;
const A4 = -1.453152027;
const A5 = 1.061405429;
const P = 0.3275911;

// Handbook of Mathematical Functions by Abramowitz and Stegun A&S
function norm_cdf(x) {
    // Save the sign of x
    const sign = x < 0.0 ? -1.0 : 1.0;
    const x_abs = Math.abs(x) / Math.sqrt(2.0);

    // A&S formula 7.1.26
    const t = 1.0 / (1.0 + P * x_abs);
    let y = 1.0 - ((((A5 * t + A4) * t + A3) * t + A2) * t + A1) * t * Math.exp(-x_abs * x_abs);

    return 0.5 * (1.0 + sign * y);
}

function norm_pdf(x) {
    return 1.0 / SQRT_OF_2_PI * Math.exp(-0.5 * x * x);
}

function positive_sqrt(t) {
    return Math.max(EPSILON_SQRT, Math.sqrt(t));
}

// days to expiration with input "07/09/2023 14:45:07"
function days_to_expiration(expiration) {
    const expiration_date = new Date(expiration);
    const today = new Date();
    const days = (expiration_date - today) / (1000 * 60 * 60 * 24);
    return days;
}

export { norm_cdf, norm_pdf, positive_sqrt, days_to_expiration };